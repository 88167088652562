var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomBottomSheet',{attrs:{"refName":'EducationalRatingInfo',"size":"xl","headerText":_vm.$t('EducationalRatings.data'),"headerIcon":_vm.educationalRating.icon}},[_c('div',{staticClass:"row"},[(_vm.educationalRating.educationalCategoryToken)?_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalRating.educationalCategoryData
          ? _vm.educationalRating.educationalCategoryData
              .educationalCategoryNameCurrent
          : '',"title":_vm.$t('EducationalCategories.name'),"imgName":'EducationalCategories.svg'}}):_vm._e(),(_vm.educationalRating.educationalGroupToken)?_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalRating.educationalGroupData
          ? _vm.educationalRating.educationalGroupData.educationalGroupNameCurrent
          : '',"title":_vm.$t('EducationalGroups.name'),"imgName":'EducationalGroups.svg'}}):_vm._e(),(_vm.educationalRating.educationalScheduleTimeToken)?_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalRating.educationalScheduleTimeData
          ? _vm.educationalRating.educationalScheduleTimeData
              .educationalScheduleTimeNameCurrent
          : '',"title":_vm.$t('EducationalScheduleTimes.name'),"imgName":'EducationalScheduleTimes.svg'}}):_vm._e(),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalRating.fullCode,"title":_vm.$t('EducationalRatings.code'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalRating.educationalRatingNumber,"title":_vm.$t('general.value'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalRating.educationalRatingTitleAr,"title":_vm.$t('EducationalRatings.titleAr'),"imgName":'educationalRatings.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalRating.educationalRatingTitleEn,"title":_vm.$t('EducationalRatings.titleEn'),"imgName":'educationalRatings.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalRating.educationalRatingNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }